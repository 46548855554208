import React from 'react';
import TweenOne from 'rc-tween-one';
import './../../assets/styles/OthersTitle.css'


const OthersTitle = props => {
    const TITLE_LINE_1 = `Veja também`;
    const TITLE_LINE_2 = `outros títulos`;
    const isSurprise = (props.appState === 'firsttimevisit')
        ? true
        : false
        ;
    return (
        <TweenOne id="others_title" className={`others_title_container ${isSurprise ? 'hide' : ''}`} paused={isSurprise} animation={{ opacity: 1 }}>
            <p>{TITLE_LINE_1}</p>
            <p>{TITLE_LINE_2}</p>
        </TweenOne>
    );
}

export default OthersTitle;