import React, { Component } from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Preloader.css";

class SimpleLoader extends Component {
  static defaultProps = {
    appState: null
  };

  state = {
    isAppInitialized: false
  };

  render() {
    const screenWidth = window.innerWidth;
    const stateAppLoaded =
      this.props.appState === "inicializing" ||
        this.props.appState === "loading"
        ? false
        : true;
    if (this.state.isAppInitialized) {
      return null;
    }
    return (
      <div className="preloader_container">
        <TweenOne
          className="preloader_animate"
          paused={!stateAppLoaded}
          animation={{
            opacity: 0,
            onComplete: () =>
              this.setState({
                isAppInitialized: true
              })
          }} />
      </div>
    );
  }
}

export default SimpleLoader;
