import React from "react";
import AuthHelp from "../auth-help";
import FaleConosco from "../faleconosco";
import FormNumpadPasscode from "../auth-numpad";

const help = `Adicionar texto de ajuda para essa seção.`

const AuthCodePad = (props) => {

  const { method, toggle_callus, onSubmit, onSubmitError, onComplete, } = props;

  function ReturnButton() {
    return (
      <button
        id="button-cpf"
        className="auth_login_button_other"
        onClick={() => props?.setMethod(null)}
      >
        Voltar
      </button>
    )
  }

  return (
    <>
      <h2 className="auth-page-content-h2">
        {`Digite o código`} <br /> {`${method?.operadora}`}
      </h2>

      <div className="auth_pad_button_other">

        <FormNumpadPasscode method={method} onSubmit={onSubmit} onSubmitError={onSubmitError} onComplete={onComplete} />
        <AuthHelp dicalogin={method?.dicalogin || help} />

        <div style={{ flex: 1, flexDirection: 'row' }}>
          <ReturnButton />
          <FaleConosco toggle_callus={toggle_callus} />
        </div>

      </div>
    </>
  );
};

export default AuthCodePad;
