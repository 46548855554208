import React from "react";
import "./styles.css";

const Regulamento = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <h2 className="title">REGULAMENTO DA PLATAFORMA EULALIA LIVROS</h2>
        <br />
        <p>
          <strong>1. O OBJETO</strong>
        </p>
        <p>
          O Presente Termo regula as condi&ccedil;&otilde;es de uso
          da&nbsp;Eulalia Livros, plataforma de distribui&ccedil;&atilde;o de
          digitais e audiolivros que permite a seus usu&aacute;rios, doravante
          Participantes Usu&aacute;rios, receber mensalmente um t&iacute;tulo,
          por eles adquirido, em formato eletr&ocirc;nico, que poder&aacute; ser
          baixado pelos Participantes Usu&aacute;rios para leitura em seus
          dispositivos como smartphone, tablet ou computador.
        </p>
        <p>
          O Regulamento ora apresentado, segue, dentre outros, os
          princ&iacute;pios da informa&ccedil;&atilde;o, transpar&ecirc;ncia e
          boa-f&eacute;, observando as leis n&ordm; 8.069/1990, n&ordm;
          8.078/1990 e n&ordm; 10.406/2002.
        </p>
        <p>
          Os Participantes Usu&aacute;rios declaram que ao acessarem e
          utilizarem a plataforma Eulalia Livros leram integralmente e aceitaram
          os termos deste Regulamento na forma como aqui expressos, tendo acesso
          aos t&iacute;tulos distribu&iacute;dos.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>2. O ACESSO &Agrave; EULALIA LIVROS</strong>
        </p>
        <p>
          2.1. Poder&atilde;o ser Participantes Usu&aacute;rios somente as
          pessoas jur&iacute;dicas e as pessoas f&iacute;sicas capazes maiores
          de 18 (dezoito) anos.
        </p>
        <p>
          2.2. O Participante Usu&aacute;rio, nos termos da
          legisla&ccedil;&atilde;o aplic&aacute;vel &agrave; esp&eacute;cie,
          declara desde j&aacute; que o seu cadastro para
          utiliza&ccedil;&atilde;o da plataforma, e consequente
          aceita&ccedil;&atilde;o deste Regulamento, &eacute; respons&aacute;vel
          pelos seus atos na utiliza&ccedil;&atilde;o da Eulalia Livros, no caso
          de qualquer conduta il&iacute;cita, na forma das
          legisla&ccedil;&otilde;es vigentes, bem como pela eventual
          utiliza&ccedil;&atilde;o indevida ou por propaga&ccedil;&atilde;o do
          material disponibilizado sem pr&eacute;via e expressa
          autoriza&ccedil;&atilde;o.
        </p>
        <p>
          2.3. Ap&oacute;s ci&ecirc;ncia de todos os termos do presente
          regulamento, o Participante Usu&aacute;rio ter&aacute; acesso
          &agrave;s edi&ccedil;&otilde;es digitais, sendo de sua inteira
          faculdade baixar os t&iacute;tulos oferecidos, observando os prazos
          previstos no item 3.5.
        </p>
        <p>
          2.4. Os t&iacute;tulos a serem baixados ser&atilde;o disponibilizados
          em arquivos formato ePUB, que ficam armazenados no dispositivo do
          Usu&aacute;rio Participante.
        </p>
        <p>
          2.5. Antes baixar os t&iacute;tulos disponibilizados, o Participante
          Usu&aacute;rio dever&aacute; reler integralmente este Regulamento.
          Caso opte pelo download dos livros, Participante Usu&aacute;rio
          concordar&aacute; automaticamente que houve expressa
          aceita&ccedil;&atilde;o e concord&acirc;ncia de todas as regras,
          termos e condi&ccedil;&otilde;es previstos neste Regulamento.
          N&atilde;o ser&aacute; poss&iacute;vel a utiliza&ccedil;&atilde;o da
          Eulalia Livros caso haja qualquer discord&acirc;ncia aos presentes
          termos.
        </p>
        <p>
          2.6. O Participante Usu&aacute;rio se declara propriet&aacute;rio dos
          exemplares que lhe foram disponibilizados na plataforma Eulalia
          Livros.
        </p>
        <p>
          2.7. A elegibilidade &agrave; Eulalia Livros, salvo quando contratada
          diretamente pelo Participante Usu&aacute;rio, &eacute; definida a
          crit&eacute;rio exclusivo da empresa parceira a que o Participante
          Usu&aacute;rio est&aacute; vinculado, e que mant&eacute;m acordo
          comercial com a Eulalia Livros. A elegibilidade pode ainda ser
          estabelecida a partir da contrata&ccedil;&atilde;o direta do acesso
          &agrave; plataforma pelo Usu&aacute;rio Participante, por meio de
          solicita&ccedil;&atilde;o na pr&oacute;pria plataforma.&nbsp;
        </p>
        <p>
          2.8. O conte&uacute;do oferecido pela Eulalia Livros &eacute;
          destinado ao p&uacute;blico adulto, este entendido como o maior capaz
          previsto no item 2.1 e definido nos arts. 3&ordm;, 4&ordm; e 5&ordm;
          da Lei n&ordm; 10.406/2002.
        </p>
        <p>
          2.9. A despeito da criteriosa sele&ccedil;&atilde;o de t&iacute;tulos
          e curadoria do conte&uacute;do oferecido, a Eulalia Livros n&atilde;o
          se responsabiliza, em hip&oacute;tese alguma, por quaisquer danos que
          a exposi&ccedil;&atilde;o do conte&uacute;do possa causar,
          particularmente sobre p&uacute;blico inadequado e terceiros, bem como
          o acesso a plataforma por menores de 18 (dezoito) anos, cabendo ao
          Participante Usu&aacute;rio, n&atilde;o permitir o acesso e a
          exposi&ccedil;&atilde;o do conte&uacute;do, sobretudo ao
          p&uacute;blico infantil.
        </p>
        <p>
          2.10. &Eacute; de responsabilidade exclusiva dos Participantes
          Usu&aacute;rios a op&ccedil;&atilde;o pelo acesso e download dos
          t&iacute;tulos adquiridos, assim como fornecer acesso ao
          conte&uacute;do oferecido a terceiros n&atilde;o usu&aacute;rios da
          plataforma Eulalia Livros e a menores de 18 anos de idade,
          independente de assist&ecirc;ncia ou representa&ccedil;&atilde;o.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            3. A PARTICIPA&Ccedil;&Atilde;O E A UTILIZA&Ccedil;&Atilde;O DE
            CONTE&Uacute;DO
          </strong>
        </p>
        <p>
          3.1. A Eulalia Livros &eacute; uma plataforma exclusiva, de
          ades&atilde;o volunt&aacute;ria pelos Participantes Usu&aacute;rios,
          por meio de contrata&ccedil;&atilde;o direta ou v&iacute;nculo com as
          empresas parceiras da&nbsp;Eulalia Livros. A elegibilidade ao acesso
          &agrave; plataforma, salvo contrata&ccedil;&atilde;o direta, &eacute;
          definida pelas empresas parceiras a seu exclusivo crit&eacute;rio,
          cabendo aos eleg&iacute;veis a decis&atilde;o de consumar ou
          n&atilde;o sua ades&atilde;o por meio do download dos livros.
        </p>
        <p>
          3.2. &Eacute; direito dos Participantes Usu&aacute;rios, a qualquer
          tempo e sem necessidade de justificativa pr&eacute;via, solicitar a
          suspens&atilde;o do envio de comunica&ccedil;&otilde;es pelo Eulalia
          Livros mediante comunica&ccedil;&atilde;o expressa, diretamente ao
          Eulalia Livros por meio da se&ccedil;&atilde;o Fale Conosco ou aos
          canais de relacionamento da empresa parceira a qual esteja vinculado.
          A suspens&atilde;o de comunicados n&atilde;o cancela a elegibilidade
          dos Participantes Usu&aacute;rios, que poder&atilde;o, a qualquer
          momento, enquanto eleg&iacute;veis, solicitar restabelecimento do
          envio de comunica&ccedil;&otilde;es do Eulalia Livros
        </p>
        <p>
          3.3. Poder&aacute;, a qualquer momento e a crit&eacute;rio exclusivo e
          iniciativa da empresa parceira do Eulalia Livros a qual o Participante
          Usu&aacute;rio est&aacute; vinculado, haver o cancelamento da
          participa&ccedil;&atilde;o de usu&aacute;rios na medida que estas
          apontem ao Eulalia Livros o fim da elegibilidade do Participante
          Usu&aacute;rio. O cancelamento acarretar&aacute; o n&atilde;o
          recebimento de novos t&iacute;tulos pelo Participante Usu&aacute;rio.
        </p>
        <p>
          3.4. O cancelamento da elegibilidade do Participante Usu&aacute;rio
          n&atilde;o afeta o acesso e a leitura dos conte&uacute;dos j&aacute;
          adquiridos, observado o prazo do item 3.5, sendo de propriedade do
          Participante Usu&aacute;rio os t&iacute;tulos por ele baixados a
          qualquer tempo. O cancelamento da elegibilidade e por
          consequ&ecirc;ncia, da participa&ccedil;&atilde;o do Participante
          Usu&aacute;rio n&atilde;o impede o download futuro, at&eacute; o
          per&iacute;odo de um ano desde a data de
          disponibiliza&ccedil;&atilde;o dos t&iacute;tulos no per&iacute;odo de
          elegibilidade, mesmo ao usu&aacute;rio que tenha se tornado
          ineleg&iacute;vel.
        </p>
        <p>
          3.5. Todo o novo conte&uacute;do entregue mensalmente ao
          Usu&aacute;rio Participante estar&aacute; dispon&iacute;vel para
          download pelo per&iacute;odo de um (1) ano a contar da data de
          disponibiliza&ccedil;&atilde;o na plataforma, nos termos do que
          disp&otilde;e o art. 3&ordm;, inciso II do Decreto n&ordm; 7.962 de 15
          de mar&ccedil;o de 2013. Ap&oacute;s este per&iacute;odo, n&atilde;o
          ser&aacute; poss&iacute;vel para o Usu&aacute;rio participante baixar
          os t&iacute;tulos disponibilizados em datas anteriores. O
          conte&uacute;do de meses anteriores &agrave; ades&atilde;o, oferecido
          como b&ocirc;nus ao Usu&aacute;rio Participante, estar&aacute;
          dispon&iacute;vel por per&iacute;odos menores, que variam de acordo
          com a data de in&iacute;cio da disponibiliza&ccedil;&atilde;o do
          conte&uacute;do na plataforma, independentemente da data de
          ades&atilde;o do Usu&aacute;rio Participante.
        </p>
        <p>
          3.6. Considerando os princ&iacute;pios da informa&ccedil;&atilde;o e
          transpar&ecirc;ncia, A perda de acesso a conte&uacute;do
          disponibilizado h&aacute; mais de um ano pelo Participante
          Usu&aacute;rio n&atilde;o dar&aacute; direito a nenhuma
          compensa&ccedil;&atilde;o, indeniza&ccedil;&atilde;o ou
          repara&ccedil;&atilde;o.
        </p>
        <p>
          3.7. Os Participantes Usu&aacute;rios dever&atilde;o estar cientes de
          que o acesso ao Eulalia Livros e o download de t&iacute;tulos depende
          de tr&aacute;fego de dados pela rede de dados, estando, pois, sujeito
          &agrave; consumo de dados, tarifa&ccedil;&atilde;o e encargos pela
          operadora de telecomunica&ccedil;&otilde;es.
        </p>
        <p>
          3.8. A Eulalia Livros reserva-se o direito de, a qualquer momento e a
          seu exclusivo crit&eacute;rio, descontinuar, suspender, terminar a
          oferta da plataforma, ou ainda alterar a quantidade, o formato e a
          periodicidade dos t&iacute;tulos disponibilizados, mantendo a
          disponibilidade prevista no item 3.5.
        </p>
        <p>
          3.9. Para hip&oacute;tese de encerramento da Eulalia Livros,
          ter-se-&aacute; pr&eacute;via comunica&ccedil;&atilde;o de 30 (trinta)
          dias aos Participantes Usu&aacute;rios.
        </p>
        <p>
          3.10. A plataforma Eulalia Livros &eacute; de uso pessoal e n&atilde;o
          comercial, da qual os Participantes Usu&aacute;rios poder&atilde;o ser
          exclu&iacute;dos e/ou ter a plataforma suspensa, definitiva ou
          temporariamente, pelos seguintes motivos, dentre outros:
        </p>
        <p>
          a. compartilhar, ceder, comercializar, divulgar ou imprimir os
          arquivos baixados;
        </p>
        <p>
          b. modificar, adaptar, licenciar, transferir, editar, publicar,
          traduzir, promover publica&ccedil;&atilde;o em qualquer outro meio de
          comunica&ccedil;&atilde;o, criar outras obras derivadas das
          disponibilizadas, ou de qualquer forma explorar ou tirar proveito do
          conte&uacute;do digital da plataforma Eulalia Livros;
        </p>
        <p>
          c. comercializar, locar ou transferir o acesso &agrave; plataforma
          Eulalia Livros;
        </p>
        <p>
          d. adaptar, modificar, comercializar, distribuir, realizar engenharia
          no c&oacute;digo fonte da plataforma ou praticar qualquer ato que
          comprometa, total o parcialmente, seu funcionamento, disponibilidade
          e/ou desempenho;
        </p>
        <p>
          e. identifica&ccedil;&atilde;o de qualquer ind&iacute;cio ou prova de
          v&iacute;cio, fraude ou irregularidade na participa&ccedil;&atilde;o
          Participante Usu&aacute;rio;
        </p>
        <p>
          f. por solicita&ccedil;&atilde;o dos pr&oacute;prios Participantes
          Usu&aacute;rios ou de respons&aacute;vel devidamente habilitado;
        </p>
        <p>
          g. por solicita&ccedil;&atilde;o judicial ou de autoridade competente.
        </p>
        <p>
          h. por determina&ccedil;&atilde;o e/ou a crit&eacute;rio exclusivo da
          operadora associada
        </p>
        <p>
          i. se apurado o mau uso do Eulalia Livros, al&eacute;m de infringir a
          legisla&ccedil;&atilde;o aplic&aacute;vel, especialmente de natureza
          c&iacute;vel e criminal, bem como afronta aos princ&iacute;pios
          &eacute;ticos, morais e de boa-f&eacute;, ou caso haja
          viola&ccedil;&atilde;o de alguma das disposi&ccedil;&otilde;es
          contidas neste Termo.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>4. PROPRIEDADE INTELECTUAL</strong>
        </p>
        <p>
          4.1. Todos os conte&uacute;dos disponibilizados pela&nbsp;Eulalia
          Livros, incluindo a pr&oacute;pria plataforma, est&atilde;o protegidos
          pelas Leis de Direitos Autorais e Propriedade Intelectual.
        </p>
        <p>
          4.2. O Participante Usu&aacute;rio &eacute; o propriet&aacute;rio dos
          conte&uacute;dos adquiridos e por ele baixados, sendo que, por estarem
          estes protegidos pelas leis de direitos autorias, &eacute;
          expressamente proibido ao Participante Usu&aacute;rio reproduzir,
          ainda que parcialmente, transmitir, veicular, ceder, negociar ou
          distribuir qualquer t&iacute;tulo.
        </p>
        <p>
          4.3. Ao optar fazer download de qualquer t&iacute;tulo, o Participante
          Usu&aacute;rio concorda em zelar pelos conte&uacute;dos baixados e
          respeitar os direitos autorais e a propriedade intelectual de autores
          e da plataforma, assumindo civil e criminalmente a responsabilidade
          por eventuais viola&ccedil;&otilde;es e danos de natureza moral e
          material causados a outrem, independente de natureza e
          extens&atilde;o, inclusive nas hip&oacute;teses de regresso.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>5. DAS DISPOSI&Ccedil;&Otilde;ES GERAIS</strong>
        </p>
        <p>
          5.1. A Eulalia Livros declara que n&atilde;o armazena os dados
          pessoais dos Participantes Usu&aacute;rios tampouco compartilha com
          terceiros sua atividade relacionada a visitas e downloads.
        </p>
        <p>
          5.2. Os Participantes Usu&aacute;rios reconhecem o direito da Eulalia
          Livros, a qualquer tempo e por ato unilateral, de aditar, modificar ou
          atualizar as regras, termos e condi&ccedil;&otilde;es deste
          Regulamento. Qualquer aditamento, modifica&ccedil;&atilde;o ou
          atualiza&ccedil;&atilde;o ser&atilde;o previamente comunicados aos
          Participantes Usu&aacute;rios, por meio do site ou por correio
          eletr&ocirc;nico. Caso os Participantes Usu&aacute;rios continuem a
          usar o Eulalia Livros depois do aviso de aditamento,
          modifica&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o deste
          Regulamento, isso caracterizar&aacute; a aceita&ccedil;&atilde;o das
          altera&ccedil;&otilde;es implementadas. Excluem-se as
          modifica&ccedil;&otilde;es que gerem &ocirc;nus ou novas
          cobran&ccedil;as ao usu&aacute;rio.
        </p>
        <p>
          5.3. A Eulalia Livros poder&aacute;, a qualquer tempo e por ato
          unilateral, ser suspenso, extinto ou modificado, ou ter sua
          gest&atilde;o transferida, mediante pr&eacute;via
          comunica&ccedil;&atilde;o aos Participantes Usu&aacute;rios, por meio
          do site{" "}
          <a href="http://www.eulalialivros.com.br">www.eulalialivros.com.br</a>
          , por correio eletr&ocirc;nico aos Participantes Usu&aacute;rios. Em
          qualquer hip&oacute;tese, ser&aacute; resguardada eventual
          opera&ccedil;&atilde;o comercial j&aacute; conclu&iacute;da pelos
          Participantes Usu&aacute;rios at&eacute; a data da suspens&atilde;o,
          extin&ccedil;&atilde;o ou modifica&ccedil;&atilde;o.
        </p>
        <p>
          5.5. Os Participantes reconhecem a obrigatoriedade de observar as leis
          de prote&ccedil;&atilde;o ao direito autoral e propriedade intelectual
          e concordam em faz&ecirc;-lo, estando sujeitos, em contr&aacute;rio,
          &agrave;s penalidades c&iacute;veis e criminais aplic&aacute;veis.
        </p>
        <p>
          5.6. Os casos omissos neste Regulamento ser&atilde;o tratados
          diretamente entre os Participantes Usu&aacute;rios e o Eulalia Livros,
          observando a legisla&ccedil;&atilde;o pertinente.
        </p>
        <p>
          5.7. Qualquer toler&acirc;ncia ao descumprimento ou omiss&atilde;o na
          execu&ccedil;&atilde;o de seus direitos por parte da Eulalia Livros,
          n&atilde;o constituir&aacute; ren&uacute;ncia, inefic&aacute;cia ou
          nova&ccedil;&atilde;o dos direitos e obriga&ccedil;&otilde;es ora
          pactuados, nem impedir&aacute; que a Eulalia Livros ou qualquer dos
          autores e/ou editores veiculados na plataforma, ainda que
          extemporaneamente, requeira o cumprimento de tais direitos e
          obriga&ccedil;&otilde;es, sendo certo que qualquer
          exce&ccedil;&atilde;o s&oacute; se tornar&aacute; v&aacute;lida- desde
          que expressamente concedida pelo Eulalia Livros ou qualquer dos
          parceiros disponibilizados por meio da plataforma.
        </p>
        <p>
          5.8. A Eulalia Livros n&atilde;o ser&aacute; considerada em mora ou
          inadimplente em rela&ccedil;&atilde;o a qualquer direito ou
          obriga&ccedil;&atilde;o previstos neste Regulamento se o motivo do
          descumprimento decorrer de caso fortuito ou for&ccedil;a maior, na
          forma estabelecida pelo C&oacute;digo Civil Brasileiro, Lei. n&ordm;
          10.406, de 10 de janeiro de 2002 e pelo C&oacute;digo de
          Prote&ccedil;&atilde;o e Defesa do Consumidor, Lei n&ordm; 8.078 de 11
          de setembro de 1990.
        </p>
        <p>
          5.9. Na hip&oacute;tese de qualquer das regras, termos ou
          condi&ccedil;&otilde;es aqui dispostos ser considerado, por qualquer
          motivo, por ju&iacute;zo ou &aacute;rbitro competente,
          inv&aacute;lido, inexequ&iacute;vel ou ilegal, no todo ou em parte, a
          validade e exequibilidade das demais regras, termos e
          condi&ccedil;&otilde;es, ou de partes deles, n&atilde;o ser&atilde;o
          afetadas, pois, tratam-se de regras independentes entre si, nos termos
          do que disp&otilde;e o art. 51, &sect; 2&ordm; da lei 8.078/90.
        </p>
        <p>
          5.10. Independentemente do local no territ&oacute;rio, nacional ou
          internacional, que os Participantes Usu&aacute;rios tenham acesso ao
          ambiente de internet do Eulalia Livros, este Regulamento ser&aacute;
          regido, interpretado e executado de acordo com as leis da
          Rep&uacute;blica Federativa do Brasil, independentemente dos conflitos
          dessas leis com leis de outros estados ou pa&iacute;ses, sendo
          competente a Justi&ccedil;a Brasileira para dirimir qualquer
          d&uacute;vida decorrente deste instrumento. O aceite ao presente
          Regulamento estabelece a ren&uacute;ncia dos Participantes
          Usu&aacute;rios &agrave; compet&ecirc;ncia de qualquer outra
          autoridade judici&aacute;ria, FICANDO DESDE J&Aacute; ELEITO O FORO DO
          DOMIC&Iacute;LIO DO PARTICIPANTE como competente para dirimir qualquer
          quest&atilde;o oriunda do presente Regulamento, por mais privilegiado
          que seja ou venha a ser.
        </p>
      </div>

      <button
        className="home-button"
        onClick={() => (window.location.pathname = "/")}
      >
        Sair
      </button>
    </div>
  );
};

export { Regulamento };
