import React, { Component } from "react";
import Carousel from "nuka-carousel";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/OthersBooks.css";

// TODO: Transformar em compoennte funcional

class OthersBooks extends Component {
  state = {
    SLIDES: [],
    currentSlide: 0,
  };

  componentDidMount() {
    return this.loadBooks();
  }

  loadBooks() {
    let curentBooks = [];
    this.props.payload.listData.reverse().map(item => {
      // aqui neste momento é possível eliminar
      // algum livro da lista se necessário
      curentBooks.push(
        <div key={item.bookId}>
          <div
            id={item.bookId}
            onClick={this.handleClick}
            className="others_books_slide"
            style={{ backgroundImage: `url(${item.bookCover})` }}
          />
          <p id="availabilityMonth">{item.bookMonthName}</p>
          <p id="availability">Baixe até</p>
          <p id="availabilityDate">{item.bookAvailability}</p>
        </div>
      );
    });
    this.setState({
      SLIDES: curentBooks,
      currentSlide: curentBooks.length < 2 ? 0 : curentBooks.length - 2
    });
  }

  handleClick = e => {
    const slides = this.state.SLIDES;
    const totalItens = slides.length;
    const id = e.target.id;
    // aqui é a seleção do slide no carrossel 
    for (let j = 0; j < totalItens; j++) {
      const element = slides[j];
      if (id === element.key) {
        // marca o slide para exibir
        this.setState({ currentSlide: j }, () => {
          setTimeout(() => {
            // aciona o scroll pro topo
            window.scrollTo(0, 0)
            this.props.onSlideChange(id);
          }, 200);
        });
        // finaliza o "for"
        j = totalItens;
      }
    }
  }

  render() {
    const isSurprise = this.props.appState === "firsttimevisit" ? true : false;
    const slidesToShow = 1;
    return (
      <div className="others_books_container_overflow">
        <TweenOne
          id="others_books"
          className={`others_books_container ${isSurprise ? "hide" : ""}`}
          paused={isSurprise}
          animation={{
            opacity: 1
          }}
        >
          <Carousel
            withoutControls={false}
            slidesToShow={slidesToShow}
            slideIndex={this.state.currentSlide}
            renderBottomLeftControls={({ previousSlide }) => (
              <a className="others_books_nav_buttons" onClick={previousSlide}>
                {"<<"}
              </a>
            )}
            renderBottomRightControls={({ nextSlide }) => (
              <a className="others_books_nav_buttons" onClick={nextSlide}>
                {">>"}
              </a>
            )}
          >
            {this.state.SLIDES}
          </Carousel>
        </TweenOne>
      </div>
    );
  }
}

export default OthersBooks;
