import React, { useState } from "react";
import Drawer from "react-drag-drawer";
import "./../../assets/styles/InfoButtons.css";
import CallUsModal from "../faleconosco/faleconosco-modal";

const InfoButtons = () => {
  const [show_about, toggle_about] = useState(false);
  const [show_doubt, toggle_doubt] = useState(false);
  const [show_callus, toggle_callus] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <div id="info_buttons" className="info_buttons_container">
      <div className="info_buttons_wrap">
        <button id="about" onClick={() => toggle_about(true)}>
          Sobre a Eulalia
        </button>
        <button id="faq" onClick={() => toggle_doubt(true)}>
          Dúvidas
        </button>
        <button
          id="contact"
          onClick={() => {
            toggle_callus(true);
            setIsSuccess(false);
          }}
        >
          Fale com a Eulalia
        </button>
        <button
          id="contact"
          onClick={() => {
            window.localStorage.removeItem('@eulalia/hash');

            window.location.reload();
          }}
        >
          Sair
        </button>
      </div>

      <Drawer dontApplyListeners={true} open={show_about} onRequestClose={() => toggle_about(false)}>
        <div className="modal">
          <div className="modal-container">
            <h2>SOBRE A EULALIA</h2>
            <p>
              Olá, eu sou a Eulalia! Como você, sou apaixonada por livros.
            </p>
            <p>
              Todos os meses seleciono para você, com todo cuidado, um novo livro para ler, ouvir e baixar. Os títulos, sempre grandes clássicos, promovem o encontro dos leitores com alguns dos maiores autores da literatura brasileira e universal. As histórias, mais curtas que os romances, são ideais para ler online e para que o leitor possa ampliar e diversificar suas leituras.
            </p>
            <p>
              Nossas edições são cuidadosamente revisadas e atualizadas, para entregar a melhor experiência na leitura dos clássicos.
            </p>
            <p>
              Aproveite! E se tiver dúvidas, comentários ou sugestões, escreva para mim!
            </p>
          </div>
          <div className="modal-button-container">
            <button onClick={() => toggle_about(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>

      <Drawer
        dontApplyListeners={true}
        modalElementClass="card"
        parentElement={document.body}
        direction="bottom"
        open={show_doubt}
        onRequestClose={() => toggle_doubt(false)}
      >
        <div className="modal">
          <div className="modal-container">
            <h2>DÚVIDAS</h2>

            <p><b>Quantos livros recebo por m&ecirc;s?<br /><br /></b>A Eulalia seleciona sempre um novo livro a cada m&ecirc;s.<b></b></p>
            <p><b>Quando chegam os novos livros?<br /><br /></b>Os novos livros chegam no primeiro dia do m&ecirc;s.<b></b></p>
            <p><b>Para que idade os livros s&atilde;o adequados?<br /></b><br />O p&uacute;blico-alvo dos livros s&atilde;o os adultos, maiores de 18 anos. No entanto, muitos deles podem ser apropriados para os mais jovens. Cabe ao adulto, que acessa a plataforma, a decis&atilde;o de compartilhar ou n&atilde;o os livros com os menores sob sua supervis&atilde;o.<b></b></p>
            <p><b>Posso escolher meus livros?<br /></b><br />Um dos objetivos da Eulalia &eacute; apresentar aos leitores um panorama amplo da leitura cl&aacute;ssica universal, diversificando autores e estilos e introduzindo, por vezes, leituras at&eacute; ent&atilde;o desconhecidas para voc&ecirc;. Para isso, os livros s&atilde;o cuidadosamente preparados por uma curadoria especializada, que faz as indica&ccedil;&otilde;es que a Eulalia traz todo m&ecirc;s. Incentivamos voc&ecirc; a ler t&iacute;tulos e autores diferentes dos que j&aacute; conhece e sabe que aprecia, para expandir ainda mais seu universo liter&aacute;rio.<span class="Apple-converted-space">&nbsp;</span><b></b></p>
            <p><b>Qual a diferen&ccedil;a entre baixar um livro e ler online?<br /></b><br />Os livros da Eulalia s&atilde;o seus, para voc&ecirc; guardar pelo tempo que quiser. Mas para isso, voc&ecirc; precisa baix&aacute;-los. Voc&ecirc; tamb&eacute;m pode ler online, por&eacute;m os livros ficam dispon&iacute;veis na plataforma por 12 meses. Al&eacute;m disso, se voc&ecirc; tiver baixado um livro, vai poder l&ecirc;-lo mesmo sem estar conectado &agrave; internet.</p>
            <p><b>Quanto tempo tenho para baixar um livro?<br /><br /></b>Todos os livros ficam dispon&iacute;veis por 12 meses. A data limite para download de cada livro est&aacute; indicada na plataforma. Depois de 12 meses, n&atilde;o &eacute; mais poss&iacute;vel baixar o livro. Recomendamos que voc&ecirc; baixe seus livros para formar sua biblioteca e t&ecirc;-los em definitivo.</p>
            <p><b>Como fa&ccedil;o para ler um livro baixado?<br /><br /></b>Para ler os livros que baixou voc&ecirc; precisa ter em seu dispositivo, seja smartphone, tablet ou computador, um leitor digital, tamb&eacute;m chamado de e-reader, instalado. Nas lojas de aplicativos existem muitas op&ccedil;&otilde;es gratuitas. Em dispositivos m&oacute;veis recomendamos o Play Livros para celulares e tablets Android e o Livros ou Books para dispositivos iOS. Estes s&atilde;o os leitores digitais padr&atilde;o dos sistemas operacionais, por isso tendem a proporcionar uma melhor experi&ecirc;ncia, al&eacute;m de serem gr&aacute;tis. Em computadores, recomendamos o leitor Adobe Digital Editions, que tamb&eacute;m pode ser instalado gratuitamente. Quando voc&ecirc; baixa um livro, normalmente ele &eacute; carregado diretamente para o seu leitor, mas se isso n&atilde;o acontecer, v&aacute; at&eacute; a pasta de downloads do seu equipamento, localize o livro e abra-o usando leitor digital.<span class="Apple-converted-space">&nbsp;</span><b></b></p>
            <p><b>Posso compartilhar o conte&uacute;do com outras pessoas?<br /><br /></b>Todos os conte&uacute;dos da Eulalia Livros s&atilde;o protegidos por direitos autorais, por isso &eacute; proibido copiar, imprimir, enviar, ceder ou compartilhar. Mas incentivamos voc&ecirc; a fazer a leitura compartilhada com outras pessoas.</p>

            <h3>Não encontrou sua dúvida aqui?</h3>
            <h3>Então envie sua pergunta pelo Fale Conosco!</h3><br /><br />
          </div>
          <div className="modal-button-container">
            <button onClick={() => toggle_doubt(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>
      <CallUsModal
        show_callus={show_callus}
        toggle_callus={toggle_callus}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        show_callus_title={'Fale com a Eulalia'}
        show_callus_text1={'Ficou com dúvida ou quer dar sua opinião? Escreva para a Eulalia!'}
        show_callus_text2={'A Eulalia vai ler sua mensagem e responder rapidinho!'}
      />
    </div>
  );
};

export default InfoButtons;
