import React, { useState } from "react";
import { default_server } from "./../../services/api";
import TweenOne from "rc-tween-one";
import Drawer from "react-drag-drawer";
import "./../../assets/styles/BookDetail.css";
import Copyrights from "../copyrights";
import AudioPlayerModal from '../AudioPlayerModal';

const BookDetail = props => {
  let BOOK = 0;

  const [audioPlayerModalIsOpen, setAudioPlayerModalIsOpen] = useState(false)

  const isSurprise = props.appState === "firsttimevisit" ? true : false;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }

  const TOKEN = props.payload.token;
  const ITEM = props.payload.listData[BOOK];
  const GENDER = ""; //ITEM.bookAuthorGender === "F"? "Autora: " : "Autor: "
  const ILLUSTRATOR =
    ITEM?.bookIllustrator?.trim() === ITEM?.bookAuthor?.trim()
      ? ""
      : ITEM.bookIllustrator || "";
  const AUTHOR = ITEM.bookAuthor || "Carregando...";
  const TITLE = ITEM.bookTitle || "Carregando...";
  const NARRATOR = ITEM.bookAudioFullSpeaker || 'Carregando...';
  const BOOK_URL = ITEM.bookDownloadFile || "#";
  const BOOK_AUDIO_FULL_URL = ITEM.bookAudioFull;
  const content = `${GENDER} ${AUTHOR} \n  ${ILLUSTRATOR} `;

  const [show_how, toggle_how] = useState(false);
  const [show_download, toggle_download] = useState(false);

  const axios = require("axios");
  const postDownloadCounter = type => {
    axios.post(
      `${default_server}/quembaixou`,
      {
        livro: TITLE,
        token: TOKEN,
        produto: `${process.env.REACT_APP_DEFAULT_PRODUCT}`,
        tipo: type
      },
      {
        timeout: 10000,
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  };

  return (
    <TweenOne
      paused={isSurprise}
      animation={{ opacity: 1 }}
      id="book_detail"
      className={`book_detail_container`}
    >
      <p className="book_detail_title">{TITLE}</p>
      <p className="book_detail_infos">{content}</p>
      <br />

      <div className="book_detail_buttons_container">
        <button
          id="how-to-read"
          disabled={!("bookDownloadFile" in ITEM)}
          onClick={() => {
            props.changeReader(ITEM);
            postDownloadCounter(2);
          }}
        >
          Ler
        </button>
        <a
          href={"#"}
          onClick={() => {
            toggle_download(true);
            postDownloadCounter(1);
            setTimeout(() => {
              toggle_download(false);
              window.open(BOOK_URL, "_self");
            }, 5000);
          }}
        >
          <button disabled={!("bookDownloadFile" in ITEM)} id="download">
            Baixar
          </button>
        </a>
        <button
          id="how-to-read"
          disabled={!BOOK_AUDIO_FULL_URL}
          onClick={() => {
            setAudioPlayerModalIsOpen(true);
          }}
        >
          Ouvir
        </button>
      </div>

      <br />
      <br />
      <a
        className="book_detail_link"
        href={"#"}
        onClick={() => toggle_how(true)}
      >
        {` SAIBA MAIS `}
      </a>

      <Drawer dontApplyListeners={true} open={show_how} onRequestClose={() => toggle_how(false)}>
        <div className="modal">
          <div className="modal-container">
            <h2>SAIBA MAIS</h2>
            <p style={{ textAlign: "left" }}>
              Você pode baixar o livro para guardá-lo para sempre e ler mesmo sem estar conectado à internet. Pode ainda ler online ou ouvir o audiobook, mas isso, precisa estar conectado.
            </p>

            <p style={{ textAlign: "left" }}>
              Para ler online, basta selecionar o livro, que pode ser o do mês ou dos meses anteriores, na parte de baixo da tela, clicando sobre a capa, e então acionar o botão “LER ONLINE”.
            </p>

            <p style={{ textAlign: "left" }}>
              Para ouvir o audiolivro, clique em “OUVIR” e depois no botão de play.
            </p>

            <p style={{ textAlign: "left" }}>
              E para fazer o download, clique em “BAIXAR”. Você vai precisar de um leitor digital instalado em seu dispositivo. Recomendamos os leitores padrão dos sistemas Android (Play Livros) e iOS (Livros ou Books), mas você pode usar o e-reader que preferir, inclusive no computador. Há muitas opções gratuitas nas lojas de aplicativos. Prefira fazer o download quando estiver conectado a uma rede wi-fi para economizar seu pacote de dados.
            </p>
          </div>
          <div className="modal-button-container">
            <button onClick={() => toggle_how(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>

      <Drawer dontApplyListeners={true} open={show_download}>
        <Copyrights />
      </Drawer>

      <AudioPlayerModal
        bookTitle={TITLE}
        authorName={AUTHOR}
        narratorName={NARRATOR}
        isOpen={audioPlayerModalIsOpen}
        audioUrl={BOOK_AUDIO_FULL_URL}
        onHideModal={() => setAudioPlayerModalIsOpen(false)}
      />
    </TweenOne>
  );
};

export default BookDetail;
