import React from "react";
import "./../../assets/styles/AuthMenuLetter.css";

const AuthMenuLetter = (props) => {

  function MenuLetras() {
    const letras = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    return (
      <div className="menu-letras">
        {letras.map((letra, index) => (
          <a href={`#${letra}`} className="letra-quadrado" key={index}>
            {letra}
          </a>
        ))}
      </div>
    );
  }

  return (
    <div className="menu-container">
      <MenuLetras />
    </div>
  );
};

export default AuthMenuLetter;
