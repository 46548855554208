import React from "react";
import "./../../assets/styles/FaleConosco.css";

const FaleConosco = (props) => {
    return (
        <button
            onClick={() => props?.toggle_callus(
                true,
                'Fale com a Eulalia',
                'Ficou com dúvida ou quer dar sua opinião? Escreva para a Eulalia!',
                'A Eulalia vai ler e responder sua mensagem rapidinho!'
            )}
            id="contact"
            className="fale-conosco-button"
        >
            Fale com a Eulalia
        </button>
    );
};

export default FaleConosco;
