import React, { Component } from "react";
import Section from "../_section";
import AuthMethodLogin from "../auth-method";
import Processing from "../processing";
import CallUsModal from "../faleconosco/faleconosco-modal";
import HelpModal from "../help-modal/index";
import imgLoader from './../../assets/images/wrongpass-page-login.png';
import "./../../assets/styles/AuthLoginForm.css";
import AuthTitle from '../auth-title'

const modalTitleFale = 'Fale com a Eulalia'
const modalTextFale1 = 'Ficou com dúvida ou quer dar sua opinião? Escreva para a Eulalia!'
const modalTextFale2 = 'A Eulalia vai ler sua mensagem e responder rapidinho!'
const modalTitleAssine = 'ASSINE A EULALIA'
const modalText1Assine = 'Assine a Eulalia Livros por R$29,90 por mês. Envie uma mensagem e faremos contato para realizar sua assinatura!'
const modalText2Assine = 'Antes de assinar, verifique se é você cliente de uma das empresas parceiras. Você pode já ter acesso à Eulalia Livros.'

class AuthPage extends Component {

  static defaultProps = {
    appState: null,
    startDelay: 1000,
    onRefresh: () => console.log("missing refresh callback function"),
  };

  constructor(props) {
    super(props);
    this.processing = React.createRef();
  }

  state = {
    isReady: false,
    loadingRef: null,
    show_callus: false,
    show_callus_title: '',
    show_callus_text1: '',
    show_callus_text2: '',
    isSuccess: false,
    isError: false,
    phoneInput: true,
    show_help: false,
    welcomeShow: true,
  };

  render() {
    const { isError, isReady, welcomeShow } = this.state;
    const appState = this.props.appState;
    const isntAppInitialized = appState === "login";

    // dangerous situation ////////////////
    if (appState === 'login' && !isReady) {
      setTimeout(() => {
        this.setState({ isReady: true })
      }, 600);
    }
    ///////////////////////////////////////

    // if (!isntAppInitialized || !isReady) {
    if (!isntAppInitialized) {
      return null;
    }

    return (
      <Section parent={this.props}>
        {/* HACK ERROR404 COMPONENT */}
        <div className={`${isError ? 'error404_container' : 'hide'}`}>
          <img src={imgLoader} alt={'Reiniciando...'} />
        </div>
        {/* HACK ERROR404 COMPONENT */}
        <HelpModal
          toggle_help={this.toggle_help}
          show_help={this.state.show_help}
        />
        <CallUsModal
          show_callus_title={this.state.show_callus_title}
          show_callus_text1={this.state.show_callus_text1}
          show_callus_text2={this.state.show_callus_text2}
          show_callus={this.state.show_callus}
          toggle_callus={this.toggle_callus}
          setIsSuccess={this.setIsSuccess}
          isSuccess={this.state.isSuccess}
        />
        <Processing ref={this.processing} />
        <div className={`auth-page-content-${welcomeShow ? 'main' : 'secondary'} ${isError ? 'hide' : null}`}>
          {welcomeShow ? (
            <>
              <AuthTitle />

              <button
                className="auth_page_primary_button"
                onClick={() => this.setState({ welcomeShow: !welcomeShow })}
                id="contact"
              >
                COMEÇAR
              </button>
              <button className="auth_page_secondary_button" onClick={() => this.toggle_callus(true, modalTitleFale, modalTextFale1, modalTextFale2)}>
                Fale com a Eulalia
              </button>
              <button className="auth_page_secondary_button" onClick={() => this.toggle_callus(true, modalTitleAssine, modalText1Assine, modalText2Assine)}>
                Assine
              </button>
            </>
          ) : (
            <AuthMethodLogin
              onSubmit={() => this.processing.current.show()}
              onSubmitError={() => {
                // this.processing.current.hide();
                // this.props.onError();
                this.setState({ isError: true });
                setTimeout(() => this.setState({ isError: false }), 3000);
              }}
              onComplete={this.props.onRefresh}
              phoneInput={this.state.phoneInput}
              toggle_callus={this.toggle_callus}
              onReturn={() => this.setState({ welcomeShow: !welcomeShow })}
            />
          )}
        </div>
      </Section>
    );
  }

  setPhoneInput = (bool) => {
    this.setState({ phoneInput: bool });
  };

  setIsSuccess = (mode) => {
    this.setState({ isSuccess: mode });
  };

  toggle_callus = (mode, textTitle, textText1, textText2) => {
    this.setState({ show_callus: mode, show_callus_title: textTitle, show_callus_text1: textText1, show_callus_text2: textText2 });
  };
  toggle_help = (mode) => {
    this.setState({ show_help: mode });
  };

}

export default AuthPage;
