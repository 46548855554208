import React, { useState } from "react";
import AuthListPartners from "../auth-list";
import AuthCodePad from "../auth-code-pad";
import AuthCodeForm from "../auth-form";

const AuthMethodLogin = (props) => {
  const [method, setMethod] = useState(null);
  const { toggle_callus, onSubmit, onSubmitError, onComplete, } = props;

  if (!method) {
    return (
      <AuthListPartners
        callbackLodadeMethod={(payload) => setMethod(payload)} onReturn={props?.onReturn} />
    );
  }

  if (method?.formfieldslogin[0]?.name === "codigo") { // Pin Code Method
    return (
      <AuthCodePad
        method={method}
        setMethod={setMethod}
        toggle_callus={toggle_callus}
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        onComplete={onComplete}
      />
    );
  }
  else { // All Form Code Method
    return (
      <AuthCodeForm
        method={method}
        setMethod={setMethod}
        toggle_callus={toggle_callus}
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        onComplete={onComplete}
      />
    );
  }

};

export default AuthMethodLogin;
