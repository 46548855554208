import React, { useEffect, useState } from 'react';
import firebase from 'firebase';

import Section from '../_section';
import Logo from '../logo';
import MainTitle from '../main-title';
import Carrossel from '../carrossel';
import BookDetail from '../book-detail';
import Review from '../review';
import OthersTitle from '../others-title';
import OthersBooks from '../others-books';
import InfoButtons from '../info-buttons';
import InfoSmallButtons from '../info-small-buttons';
import Footer from '../footer';
import PwaScreen from '../pwa-screen';
import PushNotificationModal from '../pushNotificationModal';

const LandingPage = (props) => {
	const [isOpenSurprise, setIsOpenSurprise] = useState(null);

	const isAppInitialized = props.appState === 'firsttimevisit' || props.appState === 'landingpage' ? true : false;

	useEffect(() => {
		if (firebase.messaging.isSupported()) {
			if (window.Notification.permission === 'granted') {
				firebase.messaging().getToken().then(hash => {
					if (hash) console.log('HASH: ', hash);
				});
			}
		}
	}, []);

	useEffect(() => {
		if (isAppInitialized) {
			// registerServiceWorker();
			// askUserPermission();
		}
	}, [isAppInitialized]);

	if (!isAppInitialized) {
		return <div />;
	}

	// set body background ////////////////
	// let alternateColor = getComputedStyle(document.documentElement).getPropertyValue('--color-gray').trim();
	// document.body.style.backgroundColor = alternateColor;
	///////////////////////////////////////

	const changeReader = props.changeReader;

	return (
		<>
			<PushNotificationModal showEnable={props.appState === 'landingpage'} />

			<Section parent={props}>
				<PwaScreen />

				<Logo appState={props.appState} />
				<MainTitle isOpenSurprise={isOpenSurprise} setIsOpenSurprise={setIsOpenSurprise} />
				<Carrossel
					isOpenSurprise={isOpenSurprise}
					setIsOpenSurprise={setIsOpenSurprise}
					changeToLandingPage={props.changeToLandingPage}
					changeReader={changeReader}
				/>
				<BookDetail handleOpenReader={props.handleOpenReader} changeReader={changeReader} />
				<Review />
				<OthersTitle />
				<OthersBooks />
				<InfoButtons />
				<InfoSmallButtons />
				<Footer />

				{/* <AdsModal
					open={showAdsModal}
					onRequestClose={setShowAdsModal}
					changeToLandingPage={props.changeToLandingPage}
				/> */}
			</Section>
		</>
	);
};

export default LandingPage;
