import React, { Component } from 'react';
import { default_server } from './services/api';

import AuthPage from './components/_authpage';
import Error404 from './components/_error404';
import LandingPage from './components/_landingpage';
import SimpleLoader from './components/_loader';
import Reader from './components/_reader';
import DataManager from './services/dataManager';
import smoothscroll from 'smoothscroll-polyfill';
import './assets/styles/App.css';
import { isIOSDevice } from './utils/device';
import { Regulamento } from './components/regulamento';


let DATA_MANAGER = DataManager;
const START_DELAY = 2000;
const currentPathname = window.location.pathname;
const IS_REGULATION = currentPathname === '/regulamento';
const HAS_SUBDOMAIN = currentPathname !== '/' && currentPathname.length > 1;
const axios = require('axios');

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appState: 'inicializing',
			reader: false,
			installButton: false,
			isIos: false
		};
		// kick off the polyfill!
		smoothscroll.polyfill();
	}
	installPrompt = null;

	async componentDidMount() {
		if (HAS_SUBDOMAIN) {
			const token = currentPathname.replace(/[/"]/g, '');
			await window.localStorage.setItem('@eulalia/hash', token);
		}
		return this.inicialize();
	}

	inicialize() {
		const hash = window.localStorage.getItem('@eulalia/hash');
		const product = process.env.REACT_APP_DEFAULT_PRODUCT;


		if (hash) {
			this.setState({
				appState: 'loading'
			});

			axios({
				method: 'get',
				timeout: 20000,
				// url: 'http://www.mocky.io/v2/5e3b80423000006e002145ba',
				url: `${default_server}/getportfolio/${hash}?produto=${product}`,
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then((response) => {
					if (!'status' in response) {
						throw 'status {str} not found';
					}
					if (response.status === 200 || response.status === 201) {
						if ('listData' in response.data && response.data.listData.length === 0) {
							throw `listData {arr} not found`;
						}
						DATA_MANAGER = new DataManager(response.data);
						return this.loadFromAutoStart();
					} else {
						throw `status: ${response.status}`;
					}
				})
				.catch((error) => {
					window.localStorage.removeItem('@eulalia/hash');
					return this.loadFromError();
				});
		} else {
			return this.loadFromLogin();
		}
	}

	installApp = async () => {
		if (!this.installPrompt) return false;
		this.installPrompt.prompt();
		let outcome = await this.installPrompt.userChoice;
		if (outcome.outcome == 'accepted') {
			console.log('App Installed');
		} else {
			console.log('App not installed');
		}
		// Remove the event reference
		this.installPrompt = null;
		// Hide the button
		this.setState({
			installButton: false
		});
	};

	handleOpenReader = (readerState) => this.setState({ appState: 'reader' });

	loadFromLogin() {
		setTimeout(() => {
			this.setState({ appState: 'login' });
		}, START_DELAY);
	}

	loadFromAutoStart = () => {
		setTimeout(() => this.setState({ appState: 'firsttimevisit' }), START_DELAY);
	};

	loadFromSurpriseToLandingpage = () => {
		axios
			.post(
				`${default_server}/opencollection`,
				{
					token: DATA_MANAGER.token,
					mes: DATA_MANAGER.currentMonthName,
					ano: DATA_MANAGER.currentYear,
					produto: `${process.env.REACT_APP_DEFAULT_PRODUCT}`
				},
				{
					timeout: 20000,
					headers: {
						'Content-Type': 'application/json'
					}
				}
			)
			.then((success) => { })
			.catch((error) => { })
			.finally(() => {
				this.setState({ appState: 'landingpage' });
			});
	};

	loadFromSurpriseToLandingpageDirect = () => {
		setTimeout(() => this.setState({ appState: 'landingpage' }), 100);
	};

	loadFromBoatToLandingpageDirect = () => {
		setTimeout(() => this.setState({ appState: 'landingpage' }), 3000);
	};

	loadFromError = () => {
		this.setState({ appState: 'error404' });
	};

	loadFromCredentialsPayload = (payload) => {
		DATA_MANAGER = new DataManager(payload);

		window.localStorage.setItem('@eulalia/hash', DATA_MANAGER.token);

		// rola scroll até o topo
		window.scrollTo(0, 0);
		return this.loadFromAutoStart();
	};

	toggleFullScreen() {
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
		var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

		if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
			requestFullScreen.call(docEl);
		}
		else {
			cancelFullScreen.call(doc);
		}
	}

	changeReader = (bookId) => {
		if (!this.state.reader) {
			this.setState({ reader: bookId }, () => {
				if (!isIOSDevice) {
					this.toggleFullScreen()
				}
			});
		} else {
			this.setState({ reader: false }, () => {
				if (!isIOSDevice) {
					!!document.fullscreenElement && this.toggleFullScreen()
				}
			});
		}
	};

	disableInstallButton = () => {
		this.setState({ installButton: false });
	};

	render() {
		return (
			<div className="App" id="App">
				{
					IS_REGULATION
						?
						<Regulamento />
						:
						<>
							<SimpleLoader appState={this.state.appState} />

							<Error404 appState={this.state.appState} />

							<AuthPage
								appState={this.state.appState}
								startDelay={START_DELAY}
								payload={{}}
								onRefresh={this.loadFromCredentialsPayload}
								onError={this.loadFromError}
							/>

							<LandingPage
								changeReader={this.changeReader}
								appState={this.state.appState}
								changeState={this.loadFromSurpriseToLandingpage}
								changeToLandingPage={this.loadFromBoatToLandingpageDirect}
								startDelay={START_DELAY}
								payload={DATA_MANAGER}
								handleOpenReader={this.handleOpenReader}
							/>

							<Reader reader={this.state.reader} changeReader={this.changeReader} />
						</>
				}
			</div>
		);
	}
}

export default App;
