import React, { useEffect, useState } from "react";
import "./../../assets/styles/AuthHelp.css";
import { AiFillQuestionCircle } from "react-icons/ai";
import Drawer from "react-drag-drawer";

const AuthHelp = (props) => {

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="button-help" onClick={() => setModalOpen(true)}>
        <AiFillQuestionCircle size={40} />
      </div>
      <Drawer dontApplyListeners={true} open={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div className="modal">
          <div className="modal-container">
            <p style={{ textAlign: "left" }}>{props?.dicalogin}</p>
          </div>
          <div className="modal-button-container">
            <button onClick={() => setModalOpen(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AuthHelp;
